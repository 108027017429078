<template>
  <v-container
    class=" flex-nowrap flex-column"
    fluid="fluid"
    style="align-items: normal"
  >
    <view-toolbar title="Главная" v-model="search">
      <!-- <template #title>
          Пользователи
      </template> -->
    </view-toolbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-skeleton-loader
          :loading="true"
          transition="scale-transition"
          width="100%"
          type="table"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import viewToolbar from "@/components/common/ViewToolbar";
export default {
  components: {
    viewToolbar
  },
  data() {
    return {
      loading: false,
      search: ""
    };
  }
};
</script>
